:root {
    --font-color: #e5e9e2;
    --font-hover: #a2a6a1;
    --highlight: #84A98C;
    --primary: #354F52;
    --background: #2F3E46;
    --background-secondary: #52796F;
    --primary-active: #2F3E46;
    --shadow: #222222;
    --markdown-text: #eaeaea;
    --markdown-code-background: #354F52;
    --markdown-code-border: #124559;
    --markdown-blockquote-background: #354F52;
    --markdown-blockquote-border: #EFF6E0;
    --popup-header: #495057;
    --popup-body: #212529;
    --popup-button: #6c757d;
    --popup-button-hover: #42484d;
}

html,
body {
    -webkit-font-smoothing: antialiased;
    color: var(--font-color);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    background-color: var(--background);
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    scrollbar-width: thin;
    scrollbar-color: var(--background-secondary) var(--background);
}

header {
    background-color: var(--background-secondary);
    position: fixed;
    display: flex;
    flex: 0 0 3em;
    width: 100%;
    height: 3em;
    z-index: 200;
}

header svg {
    width: 2.5em;
    cursor: pointer;
}

main {
    display: flex;
    flex-direction: column;
    padding: calc(1% + 3em) 1% 1%; /* 3em is the height of the header */
}

h1 {
    font-size: 200%;
    margin: 0;
    padding: 0;
}

img {
    -webkit-filter: drop-shadow(0.3em 0.3em 0.3em var(--shadow));
    filter: drop-shadow(0.3em 0.3em 0.3em var(--shadow));
}

ul {
    list-style-type: none;
}

/* Navigation Bar */

#overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.nav-container {
    background-image: linear-gradient(180deg, var(--primary), var(--highlight));
    -moz-box-shadow: 0 0.5em 0.5em var(--shadow);
    -webkit-box-shadow: 0 0.5em 0.5em var(--shadow);
    box-shadow: 0 0.5em 0.5em var(--shadow);
    background-color: var(--primary);
    flex-direction: column;
    position: fixed;
    display: flex;
    padding-top: 3em;
    padding-left: 2em;
    height: 100dvh;
    width: 20em;
    z-index: 100;
    left: -2em; /* Make the div stick out to the left by 2em */
}

.nav-container img {
    align-self: center;
    cursor: pointer;
    margin: 10% 0;
    border-radius: 50%;
    width: 14em;
}

#info {
    padding: 0 5%;
}

#info h1,
h4 {
    margin: 0;
    margin-bottom: 0.5em;
}

nav {
    display: flex;
    flex-direction: column;
    height: 100%;
}

li #nav {
    display: flex;
}

#nav-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

#nav-item {
    box-shadow: inset 0.25em 0 0 0 var(--primary);
    margin: 2% 10% 2% 5%;
    padding: 3% 3% 3% 10%;
    font-size: 130%;
    text-decoration: none;
    list-style-type: none;
    cursor: pointer;
}

#nav-item.active {
    background-color: var(--primary-active);
}

#nav-item:hover {
    background-color: var(--primary-active);
}

.nav-container #social {
    justify-content: flex-end;
    display: flex;
    margin: 0.5em;
    margin-bottom: 3.5em; /* Add margin-bottom to prevent cutting off */
}

.nav-container #social img {
    cursor: pointer;
    padding-right: 0.5em;
    border-radius: 10%;
    width: 2.5em;
    margin: 0;
}

/* Main Page */

.button {
    text-align: center;
    background-color: var(--background-secondary);
    color: var(--font-color);
    padding: 1%;
    border: none;
    border-radius: 0.5vw;
    font-size: 1.5vw;
    cursor: pointer;
}

.button:hover {
    background-color: var(--primary);
}

#contact-content {
    font-size: 130%;
}

/* Markdown */

#md-content {
    background-color: var(--background-secondary);
    margin: 1% 0 1% 0;
    padding: 1%;
}

#md-content img {
    width: 50%;
    height: auto;
}

#md-content code {
    font-family: monospace;
    background-color: var(--markdown-code-background);
    padding: 0 0.5% 0 0.5%;
}

#md-content pre [class*="language-"] {
    font-family: monospace;
    display: block;
    border-radius: 0.5em;
    overflow: hidden;
    overflow-x: auto;
    white-space: pre;
    -webkit-overflow-scrolling: touch;
    border: solid 0.15em var(--markdown-code-border);
    background-color: var(--markdown-code-background);
    padding: 1%;
}

#md-content blockquote {
    background: var(--markdown-blockquote-background);
    border-left: 0.3em solid var(--markdown-blockquote-border);
    font-style: italic;
    margin: 1em 0 1em 0;
    padding: 0.5em;
    line-height: 1.5em;
}

#md-content blockquote p {
    display: inline;
}

#md-content ::-webkit-scrollbar-track {
    border-radius: 1em;
}

/* Cat Pictures */

.picture-content {
    background-color: var(--background-secondary);
    overflow: auto;
    white-space: nowrap;
    padding: 0.5% 0.5% 0 0.5%;
}

#cat {
    width: 20%;
    height: auto;
    padding: 1%;
}

.gallery {
    margin: 1% 0 1% 0;
}

/* Links */

a {
    color: var(--font-color);
}

a:hover {
    color: var(--font-hover);
    cursor: pointer;
}

u:hover {
    color: var(--font-hover);
    cursor: pointer;
}

#github-link:hover {
    color: var(--font-hover);
    cursor: pointer;
}

/* Tables */

.list-content {
    background-color: var(--background-secondary);
    flex-direction: column;
    display: flex;
    margin: 0 0 1%;
}

.list-title {
    background-color: var(--background);
    font-size: 150%;
    padding: 1%;
    margin: 0.5%;
}
#list-items {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0.5% 0.5% 0.5%;
}

#item {
    background-color: var(--background);
    white-space: nowrap; /* Prevent text wrapping */
    text-align: left;
    font-size: 150%;
    flex: 1 1 10%;
    padding: 1%;
}

#item#clickable {
    cursor: pointer;
}

#publications-list {
    display: flex;
    background-color: var(--background-secondary);
    padding: 1%;
    font-size: 120%;
    word-break: break-word;
    white-space: normal;
}

/* Popup */
.popup {
    background-color: var(--popup-body);
    box-shadow: 0 0 1em var(--shadow);
    flex-direction: column;
    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5em;
    z-index: 1000;
}

.clickable {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: default;
    z-index: 999;
}

.popup h2 {
    margin: 0;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--popup-header);
    color: var(--font-color);
    padding: 1em;
    border-radius: 0.5em 0.5em 0 0;
}

.popup-title {
    font-size: 2em;
}

.popup-content {
    color: var(--font-color);
    text-align: justify;
    padding: 1em;
    font-size: 1.5em;
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    html,
    body {
        scrollbar-width: none;
        font-size: 1.5vw;
    }

    .button {
        font-size: 2vw;
        padding: 1%;
    }

    #item {
        flex: 1 1 30%;
    }

    .popup {
        width: 70%;
    }

    #md-content img {
        width: 60%;
    }

    .picture-content {
        scrollbar-width: none;
    }
    
    #cat {
        width: 30%;
    }
}

/* Large - Mobile */
@media only screen and (min-width: 376px) and (max-width: 767px) {
    html,
    body {
        scrollbar-width: none;
        font-size: 2vw;
    }

    .button {
        padding: 1.5%;
        font-size: 3vw;
    }

    #item {
        flex: 1 1 40%;
        padding: 2%;
    }

    .popup {
        width: 80%;
    }

    #md-content img {
        width: 100%;
    }

    .picture-content {
        scrollbar-width: none;
    }

    #cat {
        width: 40%;
        padding: 2%;
    }
}

/* Medium - Small Mobile */
@media only screen and (min-width: 320px) and (max-width: 376px) {
    html, 
    body {
        scrollbar-width: none;
        font-size: 2.75vw;
    }

    .button {
        padding: 1.5%;
        font-size: 4vw;
    }

    #item {
        flex: 1 1 50%;
        padding: 2%;
    }

    .popup {
        width: 90%;
    }

    .popup-header {
        font-size: 3vw;
    }

    .popup-content {
        font-size: 4vw;
    }

    #md-content pre [class*="language-"] {
        scrollbar-width: none;
    }

    #md-content img {
        width: 100%;
    }

    .picture-content {
        scrollbar-width: none;
    }

    #cat {
        width: 50%;
        padding: 2%;
    }
}